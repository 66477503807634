import { createSlice } from '@reduxjs/toolkit';
import { PromptBuilderFormTypes } from './PromptBuilder.types';

export interface promptBuilderState {
  prompts: Record<string, PromptBuilderFormTypes>;
  currentLocalPrompt?: PromptBuilderFormTypes | null;
}

const initialState: promptBuilderState = {
  prompts: {},
  currentLocalPrompt: null,
};

const slice = createSlice({
  name: 'promptBuilder',
  initialState,
  reducers: {
    setPrompts: (state, action: { payload: PromptBuilderFormTypes }) => {
      state.prompts = { ...state.prompts, [action.payload.id]: action.payload };
    },
    setCurrentLocalPrompt: (state, action: { payload: PromptBuilderFormTypes | null }) => {
      state.currentLocalPrompt = action.payload;
    },
    clearLocalPrompt: (state) => {
      state.currentLocalPrompt = null;
    },
  },
});

export const { reducer: promptBuilderReducer } = slice;
export const { setPrompts, setCurrentLocalPrompt, clearLocalPrompt } = slice.actions;

export default slice;
