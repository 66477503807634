import React from 'react';
import { EapServices } from './EapServices';
import { createEapProvider, deleteEapProvider, editEapProvider, getEapProviders } from './api';
import { EapDetails, EapDetailsMap, EapDetailsResponseDto, EapDetailsWithId } from './types';

export const EapServicesContainer = () => {
  const [isEapDrawerOpen, setIsEapDrawerOpen] = React.useState<boolean>(false);
  const [eapDetailsToEdit, setEapDetailsToEdit] = React.useState<EapDetailsWithId | undefined>();
  const [eapProviders, setEapProviders] = React.useState<EapDetailsMap>({});

  React.useEffect(() => {
    getEapProviders().then((value) => {
      const providers = value?.eapProviders ?? [];
      setEapProviders(arrayToMap(providers));
    });
  }, []);

  const handleAddEapProvider = async (newData: EapDetails) => {
    const response = await createEapProvider(newData);
    if (response) {
      const newEap = transformResponse(response);
      setEapProviders((prev) => {
        return { ...prev, [newEap.eapId]: { ...newEap } };
      });
    }
  };

  const handleEditEapProvider = async (eapId: string, newData: EapDetails) => {
    const response = await editEapProvider(eapId, newData);
    if (response) {
      const editedEap = transformResponse(response);
      setEapProviders((prev) => {
        return { ...prev, [editedEap.eapId]: { ...editedEap } };
      });
    }
  };

  const handleDeleteEapProvider = async (eapId: string) => {
    const response = await deleteEapProvider(eapId);
    if (response) {
      setEapProviders((prev) => {
        const newState = { ...prev };
        delete newState[eapId];
        return { ...newState };
      });
    }
  };

  const openDrawer = () => {
    setIsEapDrawerOpen(true);
  };

  const closeDrawer = () => {
    setEapDetailsToEdit(undefined);
    setIsEapDrawerOpen(false);
  };

  const onSettingsButtonPress = async () => {
    setEapDetailsToEdit(undefined);
    openDrawer();
  };

  const handleEllipsisEditClick = (eapId: string) => {
    const toEdit = eapProviders[eapId];
    setEapDetailsToEdit(toEdit);
    openDrawer();
  };
  const handleEllipsisDeleteClick = (eapId: string) => {
    handleDeleteEapProvider(eapId);
  };

  const handleDrawerButtonClick = (eapDetails: EapDetails, defaultState?: EapDetailsWithId) => {
    if (defaultState?.eapId) {
      handleEditEapProvider(defaultState.eapId, eapDetails);
      setEapDetailsToEdit(undefined);
    } else {
      handleAddEapProvider(eapDetails);
      setEapDetailsToEdit(undefined);
    }

    closeDrawer();
  };

  return (
    <EapServices
      eapDrawerButtonText={drawerButtonText(eapDetailsToEdit)}
      isDrawerOpen={isEapDrawerOpen}
      values={eapDetailsToEdit}
      eapProviders={getEapProviderArray(eapProviders)}
      onSettingsButtonPress={onSettingsButtonPress}
      handleDrawerClose={closeDrawer}
      onDrawerButtonClick={handleDrawerButtonClick}
      onEllipsisEditClick={handleEllipsisEditClick}
      onEllipsisDeleteClick={handleEllipsisDeleteClick}
    />
  );
};

const transformResponse = (response: EapDetailsResponseDto): EapDetailsWithId => {
  return {
    eapId: response.eap_id,
    name: response.name,
    url: response.url,
    phone: response.phone,
    details: response.details,
  };
};

const arrayToMap = (eapProviders: EapDetailsResponseDto[]) => {
  return eapProviders.reduce((acc, current) => {
    acc[current.eap_id] = transformResponse(current);
    return acc;
  }, {} as EapDetailsMap);
};

const getEapProviderArray = (collection: EapDetailsMap) => {
  const eapArray = Object.keys(collection).map((eapId) => {
    return { ...collection[eapId] };
  });

  return eapArray.sort((a, b) => {
    const name1 = a.name ?? '';
    const name2 = b.name ?? '';
    return name1.localeCompare(name2);
  });
};

const drawerButtonText = (editDetails?: EapDetailsWithId) => {
  if (editDetails !== undefined) {
    return 'Save changes';
  } else {
    return 'Add Eap Service';
  }
};
