import { completeLoginAction } from 'auth/auth.actions';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { RootState } from 'store/rootReducer';
import { IsAuthenticatedDefaultRoute } from 'types/routes';
import Analytics from 'utils/AnalyticsHelper';
import history from 'utils/history';
import { parseAccessToken } from 'utils/JwtTokenHelper';
import { getOrganizationSettings } from 'slices/appState';
import { emailHashHelper } from 'utils/EmailHashHelper';
import { useFeatureFlagClient } from 'FeatureFlagContext';

export function LoginCallback() {
  const { orgId, oidcAuth, accessToken, userId } = useSelector((s: RootState) => s.auth);
  const featureFlagClient = useFeatureFlagClient();
  const [loginCompleted, setLoginCompleted] = useState<boolean>(false);

  const successLocator = useCallback(async () => {
    if (orgId !== '' && accessToken !== '') {
      if (accessToken) {
        try {
          const user = parseAccessToken(accessToken);
          Analytics.trackEvent('LoginCompleted');
          Analytics.setUserProperty('UserId', userId);
          Analytics.setUserProperty('OrgId', orgId);

          await dispatch(getOrganizationSettings(orgId));
          emailHashHelper.saveEmailHash(oidcAuth.emailAddress);
          await featureFlagClient?.identify({
            email: oidcAuth.emailAddress,
            key: userId,
            custom: {
              orgId,
              userId,
              tier: user ? user.sub_tier : '',
            },
          });
        } catch (e) {
          console.log('Error: ', e);
        }
      }
    }
  }, [orgId, oidcAuth, accessToken, userId]);

  useEffect(() => {
    (async () => {
      await dispatch(completeLoginAction());
      setLoginCompleted(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (loginCompleted) {
        await successLocator();
        history.push(IsAuthenticatedDefaultRoute);
      }
    })();
  }, [successLocator, loginCompleted]);

  return <div></div>;
}
