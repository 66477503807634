class CsvEmptyError extends Error {
  constructor(message: string | undefined) {
    super(message); // (1)
    this.name = 'CsvNoBodyError'; // (2)
  }
}

class CsvNoBodyError extends Error {
  constructor(message: string | undefined) {
    super(message); // (1)
    this.name = 'CsvNoBodyError'; // (2)
  }
}

class CsvInvalidHeadingError extends Error {
  constructor(message: string | undefined) {
    super(message); // (1)
    this.name = 'CsvInvalidHeadingError'; // (2)
  }
}

class CsvColumnMismatchError extends Error {
  constructor(message: string | undefined) {
    super(message); // (1)
    this.name = 'CsvColumnMismatchError'; // (2)
  }
}

type ParsedCsvLine = {
  [x: string]: string;
};

type ParsedCsvFile = {
  header: string;
  contentLines: string[];
};

const parseCsvFileIntoRows = (csvLines: string[], headingsToMatch: string[]): ParsedCsvFile => {
  if (csvLines.length < 1) {
    // Need to have heading and at least one row
    throw new CsvEmptyError('CSV file is empty');
  }

  if (csvLines.length < 2) {
    // Need to have heading and at least one row
    throw new CsvNoBodyError('CSV file has only headers, missing body');
  }

  const headingRow = csvLines[0];
  const contentRows = csvLines.slice(1);

  validateCsvHeader(headingRow, headingsToMatch);

  // Check that each content row contains the correct number of items
  if (
    !contentRows.every((csvRow) => {
      return validateCsvContentLine(csvRow, headingsToMatch);
    })
  ) {
    throw new CsvColumnMismatchError(
      "Number of columns in text body doesn't match what is expected in header"
    );
  }

  const cleanedContentRows = contentRows.map((row) => {
    return cleanContentrow(row);
  });

  return { header: headingRow, contentLines: cleanedContentRows };
};

const cleanContentrow = (row: string) => {
  return row
    .split(',')
    .map((rowItem) => {
      return rowItem.trim();
    })
    .join(',');
};

const validateCsvHeader = (headingRow: string, headingsToMatch: string[]) => {
  const headings = headingRow.split(',').map((heading) => {
    return heading.trim();
  });

  if (headings.length !== headingsToMatch.length) {
    // Number of columns must be equal to number expected
    throw new CsvInvalidHeadingError('Incorrect number of columns');
  }

  if (headings.join(',') !== headingsToMatch.join(',')) {
    // Heading in CSV file must match expected headings
    throw new CsvInvalidHeadingError('Invalid Heading');
  }

  return headingsToMatch;
};

const validateCsvContentLine = (csvRow: string, headingsToMatch: string[]) => {
  const rowItems = csvRow.split(',').map((item) => {
    return item.trim();
  });

  // Check that there is exactly one item for each row, and that the number of items matches the number of columns defined by the headers
  return (
    rowItems.every((item) => {
      return item.length > 0;
    }) && rowItems.length === headingsToMatch.length
  );
};

export const parseCsvContentLine = <T>(row: string, headingsToMatch: string[]) => {
  const parsedRow: ParsedCsvLine = {};
  const rowItems = row.split(',');

  headingsToMatch.forEach((heading, index) => {
    parsedRow[heading] = rowItems[index].trim();
  });

  return parsedRow as T;
};

export const readCsvFile = async (file: File, headingsToMatch: string[]) => {
  try {
    const decoder = new TextDecoder('utf-8');
    const buffer = await file.arrayBuffer();
    const string = decoder.decode(buffer);

    const arr = string.split('\n');

    const cleanedArray = arr.map((csvLine) => {
      return csvLine.trim();
    });

    return parseCsvFileIntoRows(cleanedArray, headingsToMatch);
  } catch (err) {
    throw err;
  }
};
