import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { OrgUser } from 'common/components/FileUpload/FileUpload';
import moment from 'moment';
import { ApiRequest } from 'services/request';
import { PromptCTATypes } from './PromptBuilder.types';

const BASE_SERVICE_URL = process.env.REACT_APP_SERVICE_URL;

type PromptUserPayload = {
  ScheduledTime: string;
  Name: string;
  TriggerId: string;
  Recipients: PromptUser[];
};

type PromptUser = {
  Email: string;
  OrgName: string;
};

type UploadPromptUserFileParams = {
  triggerId: string;
  promptName: string;
  scheduleTime: Date;
  orgId: string;
  users: OrgUser[];
};

export const uploadPromptUserFile = async (
  params: UploadPromptUserFileParams
): Promise<AxiosResponse> => {
  const { triggerId, promptName, scheduleTime, orgId, users } = params;
  const dateTimeString = moment(scheduleTime).format('YYYY-MM-DDTHH:mm:ssZ');
  const promptNameWithoutSpaces = promptName.replace(/\s+/g, '_');

  const promptFileName = `${orgId}-${promptNameWithoutSpaces}`;

  const recipients = users.map((user) => {
    return { Email: user.Email, OrgName: user.OrgId };
  });

  const payload: PromptUserPayload = {
    ScheduledTime: dateTimeString,
    Name: promptFileName,
    TriggerId: triggerId,
    Recipients: recipients,
  };

  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: 'content/v1/prompt-file-upload',
    method: 'POST',
    data: payload,
  };

  const result = await ApiRequest(config);
  return result;
};

type PromptPayload = {
  triggerId: string;
  promptActions: PromptAction[];
  promptText: string;
  promptName: string;
  openTextResponse?: boolean;
  categoryId: string;
  isGroovVoice: boolean;
  responseToOpenText?: string;
  promptTags: string[];
  properties: Record<string, string>;
};

export type PromptAction = {
  action: string;
  response: string;
};

export type CreatePromptParams = {
  triggerId: string;
  promptName: string;
  promptText: string;
  promptActions: PromptAction[];
  promptTags: string[];
  isGroovVoice: boolean;
  categoryId: string;
  responseToOpenText?: string;
  openTextResponse: boolean;
  promptCTAType: PromptCTATypes;
};

export const createPrompt = async (params: CreatePromptParams): Promise<AxiosResponse> => {
  const {
    triggerId,
    promptName,
    promptText,
    promptActions,
    categoryId,
    isGroovVoice,
    responseToOpenText,
    openTextResponse,
    promptTags,
    promptCTAType,
  } = params;

  const payload: PromptPayload = {
    triggerId: triggerId,
    promptActions: promptActions,
    openTextResponse,
    promptText: promptText,
    promptName: promptName,
    categoryId: categoryId,
    promptTags: promptTags,
    isGroovVoice,
    responseToOpenText,
    properties: {
      prompt_cta_type: promptCTAType,
    },
  };

  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: 'content/v1/prompt',
    method: 'POST',
    data: payload,
  };

  const result = await ApiRequest(config);
  return result;
};

export const getPromptAttributes = async (): Promise<AxiosResponse> => {
  const getTags = true;
  const getCategories = true;

  const attributes = [...(getTags ? ['tags'] : []), ...(getCategories ? ['categories'] : [])];

  const paramString = `attributes=${attributes.join(',')}`;

  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `content/v1/prompt-attributes?${paramString}`,
    method: 'GET',
  };

  const result = await ApiRequest(config);
  return result;
};

export interface PromptAttributeResponse {
  tags: PromptTagResponse[];
  categories: PromptCategoryResponse[];
}

export interface PromptTagResponse {
  id: number;
  key: string;
  name: string;
}

export interface PromptCategoryResponse {
  id: number;
  key: string;
  display_name: string;
  sort: string;
}
