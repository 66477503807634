import React, { useMemo } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { PromptItem } from './PromptItem';
import { useAppSelector } from 'store';
import { isEqual } from 'lodash';
import { initialFilterState } from '../../GroovVoice.slice';

export default function PromptListPage() {
  const classes = useStyles();

  const { filteredPrompts, prompts, searchFilteredPrompts, searchQuery, filters } = useAppSelector(
    (state) => ({
      filteredPrompts: state.groovVoice.filteredPrompts,
      prompts: state.groovVoice.prompts,
      searchFilteredPrompts: state.groovVoice.searchFilteredPrompts,
      searchQuery: state.groovVoice.searchQuery,
      filters: state.groovVoice.filters,
    })
  );

  const records = useMemo(() => {
    if (searchQuery?.length > 0) {
      return searchFilteredPrompts;
    }
    if (
      !isEqual(
        { ...initialFilterState, createdFrom: '', createdTo: '' },
        { ...filters, createdFrom: '', createdTo: '' }
      )
    ) {
      return filteredPrompts;
    }
    return prompts;
  }, [filteredPrompts, filters, prompts, searchFilteredPrompts, searchQuery]);

  return (
    <Grid item xs={12} lg={8}>
      {records?.map((prompt) => <PromptItem prompt={prompt} key={prompt.promptId} />)}
      {records?.length === 0 && (
        <Typography className={classes.noRecords}>No matching records found.</Typography>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  noRecords: {
    margin: theme.spacing(6),
  },
}));
