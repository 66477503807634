import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getRandomColor } from '../../GroovVoice.utils';
import { PromptUserResponse } from '../../GroovVoice.d';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  userRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(10),
  },
  HeadingRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(8),
  },
  circle: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    marginRight: theme.spacing(5),
  },
  response: {
    flexGrow: 1,
    textAlign: 'left',
    textOverflow: 'clip',
    maxWidth: '80%',
    flexWrap: 'wrap',
    paddingTop: 4,
  },
  time: {
    textAlign: 'left',
    fontWeight: 400,
  },
  userContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
  },
}));

interface UserResponsesProps {
  responseList: PromptUserResponse[];
  isOpenText?: boolean;
}

export const UserResponses: React.FC<UserResponsesProps> = ({ responseList, isOpenText }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container className={classes.HeadingRow}>
        <Grid item xs={4}>
          <Typography variant="h4" className={classes.response}>
            User
          </Typography>
        </Grid>
        <Grid item xs={isOpenText ? 8 : 4}>
          <Typography variant="h4" className={classes.response}>
            Response
          </Typography>
        </Grid>
        {!isOpenText && (
          <Grid item xs={4}>
            <Typography variant="h4" className={classes.response}>
              Time
            </Typography>
          </Grid>
        )}
      </Grid>
      {responseList.map((item, index) => (
        <Grid container key={item.id} className={classes.userRow}>
          <Grid item xs={4} className={classes.userContainer}>
            <div
              className={classes.circle}
              style={{ backgroundColor: getRandomColor(index) }}></div>
            <Typography variant="body1" className={classes.response}>
              {item.username}
            </Typography>
          </Grid>
          <Grid item xs={isOpenText ? 8 : 4}>
            <Typography variant="body1" className={classes.response}>
              {item.text}
            </Typography>
          </Grid>
          {!isOpenText && (
            <Grid item xs={4}>
              <Typography variant="body1" className={classes.time}>
                {format(new Date(item.timestamp), 'yyyy-MM-dd hh:mm a')}
              </Typography>
            </Grid>
          )}
        </Grid>
      ))}
    </Box>
  );
};
