import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ReportSection } from 'utils/PowerBiReports';
import { colors } from 'styles/colors';
import { UserManagement } from './UserManagement';
import { useAppSelector } from 'store';
import { removeRoleFromUser, updateUsersWithRole, removeUser } from 'pages/Settings/api';
import { UserRole } from '../../types';
import { ToasterUtils } from 'common';
import { leaderRoleId, managerRoleId } from 'pages/Settings/constants';
import { sortRecords } from './GroovSettings.utils';

const ReportNavLink = (props: {
  label: string;
  count: number;
  isActive: boolean;
  onClick?: () => void;
}) => {
  const classes = useStyles(props);
  return (
    <div
      className={[
        classes.route,
        props.isActive ? classes.activeIndicator : classes.nonActiveIndicator,
      ].join(' ')}>
      <NavLink to={`ga-settings`} onClick={props.onClick} className={classes.routeText}>
        <Box className={classes.routeTitle}>
          <Typography variant="subtitle1">{props.label}</Typography>
          <Typography variant="subtitle1">{props.count}</Typography>
        </Box>
      </NavLink>
    </div>
  );
};

export const GroupsAndUsers: React.FC = () => {
  const classes = useStyles();
  const [activeRoute, setActiveRoute] = useState<string>('all');
  const users = useAppSelector((state) => state.settings.users);

  const managers = users.filter((user) => user.roles.some((role) => role.name === 'Manager'));

  const leaders = users.filter((user) => user.roles.some((role) => role.name === 'Leader'));

  const selectableLeaders = users.filter(
    (user) => !user.roles.some((role) => role.name === 'Leader')
  );

  const selectableManagers = users.filter(
    (user) => !user.roles.some((role) => role.name === 'Manager')
  );

  const handleAddLead = async (records: UserRole[]) => {
    await updateUsersWithRole(records, leaderRoleId);
  };

  const handleRemoveLead = async (userId: string) => {
    const userName = users.find((u) => u.userId === userId)?.name;
    ToasterUtils.confirm({
      onConfirm: async () => {
        await removeRoleFromUser(userId, leaderRoleId);
        ToasterUtils.success(`${userName} removed.`);
      },
      message: `You are about to remove the Leader role for ${userName}. Would you like to proceed?`,
      title: '',
      confirmButtonText: 'Yes',
    });
  };

  const handleAddManager = async (records: UserRole[]) => {
    await updateUsersWithRole(records, managerRoleId);
  };

  const handleRemoveManager = async (userId: string) => {
    const userName = users.find((u) => u.userId === userId)?.name;
    ToasterUtils.confirm({
      onConfirm: async () => {
        await removeRoleFromUser(userId, managerRoleId);
        ToasterUtils.success(`${userName} removed.`);
      },
      message: `You are about to remove the Manager role for ${userName}. Would you like to proceed?`,
      title: '',
      confirmButtonText: 'Yes',
    });
  };

  const handleRemoveUser = async (userId: string) => {
    const userName = users.find((u) => u.userId === userId)?.name;
    ToasterUtils.confirm({
      onConfirm: async () => {
        await removeUser(userId);
        ToasterUtils.success(`${userName} removed.`);
      },
      message: `You are about to remove ${userName}. Would you like to proceed?`,
      title: '',
      confirmButtonText: 'Yes',
    });
  };

  const GASettingsNavLinks = [
    {
      label: 'All users',
      count: users.length,
      id: 'all',
      component: () => (
        <UserManagement
          users={users}
          title={`All Users (${users.length})`}
          subTitle="All users within your organisation that have access to Groov Anywhere."
          onRemoveUser={handleRemoveUser}
        />
      ),
    },
    {
      label: 'Groov Managers',
      count: managers.length,
      id: 'manager',
      component: () => (
        <UserManagement
          users={managers}
          title={`Groov Managers (${managers.length})`}
          subTitle="All users who have access to view their team's Groov Index."
          onAddUser={handleAddManager}
          selectableUsers={sortRecords(selectableManagers, 'name', 'asc')}
          onRemoveUser={handleRemoveManager}
        />
      ),
    },
    {
      label: 'Groov Leaders',
      count: leaders.length,
      id: 'lead',
      component: () => (
        <UserManagement
          users={leaders}
          title={`Groov Leaders (${leaders.length})`}
          subTitle="All users who have access to view your organisation's Groov Index."
          onAddUser={handleAddLead}
          selectableUsers={sortRecords(selectableLeaders, 'name', 'asc')}
          onRemoveUser={handleRemoveLead}
        />
      ),
    },
  ];

  const links = GASettingsNavLinks.map((r) => (
    <ReportNavLink
      key={r.id}
      label={r.label}
      count={r.count}
      isActive={activeRoute === r.id}
      onClick={() => setActiveRoute(r.id as ReportSection)}
    />
  ));

  return (
    <Box className={classes.container}>
      <Box className={classes.sidebar}>
        <Typography variant="h3" className={classes.routeHeading}>
          Groups
        </Typography>
        <Box>{links}</Box>
      </Box>
      <Box marginY={8} marginX={4} width="100%">
        <Box>{GASettingsNavLinks.find((r) => r.id === activeRoute)?.component()}</Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      overflow: 'hidden',
      height: '100%',
    },
    sidebar: {
      flexBasis: '20%',
      backgroundColor: colors.white,
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      paddingTop: theme.spacing(5),
    },
    routeHeading: {
      marginLeft: theme.spacing(4),
      marginBottom: theme.spacing(8),
    },
    route: {
      margin: theme.spacing(2),
      width: '90%',
      position: 'relative',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    routeText: {
      fontWeight: 'bold',
      color: colors.black,
      textDecoration: 'none',
    },
    routeTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    activeIndicator: {
      border: `1px solid ${theme.palette.primary.main}`,
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    nonActiveIndicator: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  })
);
