import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, FormLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import { useAppSelector } from 'store';
import { isEqual } from 'lodash';
import { SearchBar } from 'common';
import {
  fetchGroovVoicePrompts,
  initialFilterState,
  setFilters,
  setSearchFilteredPrompts,
  setSearchQuery,
  toggleState,
  toggleType,
} from '../../GroovVoice.slice';
import { CommonSelectorOptionType, PromptItemType } from '../../GroovVoice.d';
import { PromptTags } from '../Common/PromptTags';
import { SearchAndSelect } from '../Common/SearchAndSelect';
import usePromptAttributes from 'pages/PromptBuilder/usePromptAttributes';
import { getUniqueTags } from 'pages/GroovVoice/GroovVoice.utils';

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: '1px solid',
  },
  innerBox: {
    padding: theme.spacing(2),
  },
  sectionBox: {
    padding: theme.spacing(1),
  },
  label: {
    color: '#000',
  },
  dateField: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  selectField: {
    height: 40,
    marginTop: theme.spacing(1),
  },
  chip: {
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
}));

export function ListFilters() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { categoryOptions } = usePromptAttributes();
  const { filteredPrompts, prompts, searchQuery, filters } = useAppSelector(
    (state) => ({
      filteredPrompts: state.groovVoice.filteredPrompts,
      prompts: state.groovVoice.prompts,
      searchQuery: state.groovVoice.searchQuery,
      filters: state.groovVoice.filters,
    }),
    isEqual
  );

  const tags = useMemo(() => {
    return getUniqueTags(prompts);
  }, [prompts]);
  const records = useMemo(() => {
    if (
      !isEqual(
        { ...initialFilterState, createdFrom: '', createdTo: '' },
        { ...filters, createdFrom: '', createdTo: '' }
      )
    ) {
      return filteredPrompts;
    }
    return prompts;
  }, [filteredPrompts, filters, prompts]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!e.target) return;
      const { name, value } = e.target;
      dispatch(setFilters({ [name]: value }));
      if (name === 'createdFrom') {
        dispatch(fetchGroovVoicePrompts({ startTime: value, endTime: filters.createdTo }));
      }
      if (name === 'createdTo') {
        dispatch(fetchGroovVoicePrompts({ startTime: filters.createdFrom, endTime: value }));
      }
    },
    [dispatch, filters.createdTo, filters.createdFrom]
  );

  const handleSelectChange = useCallback(
    (name: string, value: CommonSelectorOptionType[]) => {
      dispatch(setFilters({ [name]: value }));
    },
    [dispatch]
  );

  const handleStateToggle = useCallback(
    (stateId: string) => {
      dispatch(toggleState(stateId));
    },
    [dispatch]
  );

  const handleTypeToggle = useCallback(
    (typeId: string) => {
      dispatch(toggleType(typeId));
    },
    [dispatch]
  );

  const handleSearchChange = useCallback(
    (records: PromptItemType[]) => {
      dispatch(setSearchFilteredPrompts(records));
    },
    [dispatch]
  );

  const handleQueryChange = useCallback(
    (query: string) => {
      dispatch(setSearchQuery(query));
    },
    [dispatch]
  );
  const today = new Date().toISOString().split('T')[0];
  const renderDateField = (label: string, name: string, value: string) => (
    <Box display="flex" flexDirection="column">
      <FormLabel>{label}</FormLabel>
      <TextField
        type="date"
        variant="outlined"
        size="small"
        name={name}
        value={value}
        onChange={handleInputChange}
        className={classes.dateField}
        InputProps={{
          inputProps: {
            max: today,
          },
        }}
      />
    </Box>
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box className={classes.container}>
        <Box className={classes.innerBox} my={2}>
          <Typography variant="h3">Filters</Typography>
          <Box py={5}>
            <SearchBar
              records={records}
              searchKeys={['promptName', 'promptText']}
              fullWidth
              showSearchIcon
              size="small"
              setResults={(recs) => handleSearchChange(recs as PromptItemType[])}
              query={searchQuery}
              onQueryChange={handleQueryChange}
            />
          </Box>

          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>CREATED</FormLabel>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gridRowGap={8}>
              {renderDateField('From', 'createdFrom', filters.createdFrom)}
              {renderDateField('To', 'createdTo', filters.createdTo)}
            </Box>
          </Box>

          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>CATEGORY</FormLabel>
            <SearchAndSelect
              options={categoryOptions}
              value={filters.category}
              handleOnChange={(_, value) => handleSelectChange('category', value)}
            />
          </Box>

          <Box className={classes.sectionBox} my={2} hidden>
            <FormLabel className={classes.label}>SURVEY/PACK</FormLabel>
            <SearchAndSelect
              options={['Survey', 'Prompt'].map((o) => ({ id: o, label: o }))}
              value={filters.promptType}
              handleOnChange={(_, value) => handleSelectChange('promptType', value)}
            />
          </Box>

          <Box className={classes.sectionBox} my={2} hidden>
            <FormLabel className={classes.label}>STATE</FormLabel>
            <PromptTags
              tags={[
                { id: 'Active', label: 'Active' },
                { id: 'Inactive', label: 'Inactive' },
              ]}
              selectedTags={filters.states}
              onClick={handleStateToggle}
            />
          </Box>

          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>TYPE</FormLabel>
            <PromptTags
              tags={[
                { id: 'action_buttons', label: 'Action Buttons' },
                { id: '6pt_scale', label: '6pt Scale' },
                { id: 'open_text', label: 'Open Text' },
                { id: 'action_w_open_text', label: 'Action Buttons with Open Text' },
              ]}
              selectedTags={filters.types}
              onClick={handleTypeToggle}
            />
          </Box>

          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>TAGS</FormLabel>
            <SearchAndSelect
              options={tags.map((v) => ({ id: v, label: v }))}
              value={filters.tags}
              handleOnChange={(_, value) => handleSelectChange('tags', value)}
            />
          </Box>
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
}
