import { Card, ContainerLayout } from 'common';
import React, { useEffect } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { ListFilters } from './ListPage/ListFilters';
import { dispatch, getStore } from 'store';
import { fetchGroovVoicePrompts, getGroovVoiceFilters } from '../GroovVoice.slice';
import PromptListPage from './ListPage/PromptListPage';

export function GroovVoice() {
  const classes = useStyles();
  const { createdFrom, createdTo } = getGroovVoiceFilters(getStore().getState());

  useEffect(() => {
    dispatch(fetchGroovVoicePrompts({ startTime: createdFrom, endTime: createdTo }));
  }, [createdFrom, createdTo]);

  return (
    <ContainerLayout>
      <Card className={classes.card}>
        <Box className={classes.root}>
          <Typography variant="h2" className={classes.header}>
            Groov Voice
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={10}>
            <PromptListPage />
            <Grid item xs={12} lg={4}>
              <ListFilters />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </ContainerLayout>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(4),
    height: '10vh',
  },
  header: {
    paddingBottom: theme.spacing(4),
  },
  card: {
    flex: 1,
    minHeight: '80vh',
  },
}));
