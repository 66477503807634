import { RouteType } from './../types/routes';
import { exchangeToken, getOrgHighlights, getOrgSettings } from './../services/api';
import { RootState } from '../store/rootReducer';
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SurveyData } from 'services/response';
import { getShortDateString } from 'utils/DateTimeHelper';

type OrganizationSettings = {
  orgName: string;
  hubEnabled: boolean;
  supportedSurveys: SurveyData[];
  navigationItems: string[];
};

interface AppState {
  orgSettings: OrganizationSettings;
  routeType: RouteType;
  loading: string;
  reloadSurveyFlag: boolean;
}

const initialState: AppState = {
  orgSettings: {
    orgName: '',
    hubEnabled: false,
    supportedSurveys: [],
    navigationItems: [],
  },
  routeType: RouteType.Public,
  loading: 'idle',
  reloadSurveyFlag: true,
};

export const setRouteTypeAction = createAction<RouteType, 'setRouteType'>('setRouteType');
export const triggerSurveyDataReload = createAction<void, 'triggerSurveyDataReload'>(
  'triggerSurveyDataReload'
);

export const loginFromExternalAction = createAsyncThunk(
  'login',
  async (payload: { accessToken: string }, thunkApi) => {
    try {
      return await exchangeToken(payload.accessToken);
    } catch (error) {
      const err = error as AxiosError;
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getOrganizationSettings = createAsyncThunk(
  'getOrganizationSettings',
  async (orgId: string, thunkAPI) => {
    try {
      const response = await getOrgSettings(orgId);
      return {
        orgName: response.name,
        hubEnabled: response.hub_enabled,
        supportedSurveys: response.supported_surveys,
        navigationItems: response.navigationItems,
      };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const getOrganizationHighlights = createAsyncThunk(
  'getOrganizationHighlights',
  async (orgId: string, thunkAPI) => {
    try {
      const highlightResponse = await getOrgHighlights(orgId);
      let publishedDate = '',
        markdownText = '';
      if (highlightResponse.status === 200) {
        const { modified_on, markdown } = highlightResponse.data;
        const dateStr = getShortDateString(new Date(modified_on));
        publishedDate = dateStr;
        markdownText = markdown;
      }
      return { publishedDate: publishedDate, markdown: markdownText };
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

const slice = createSlice({
  name: 'appState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationSettings.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(getOrganizationSettings.fulfilled, (state, { payload }) => {
        state.loading = 'idle';
        state.orgSettings = { ...payload, hubEnabled: payload.hubEnabled === 'true' };
      })
      .addCase(setRouteTypeAction, (state, action) => {
        state.routeType = action.payload;
      })
      .addCase(triggerSurveyDataReload, (state, action) => {
        state.reloadSurveyFlag = !state.reloadSurveyFlag;
      });
  },
});

export const { reducer } = slice;

export default slice;
export const selectOrganizationSettings = (state: RootState) => state.appState.orgSettings;
