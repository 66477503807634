import { OrgUser } from 'common/components/FileUpload/FileUpload';
import { CommonSelectorOptionType } from 'pages/GroovVoice/GroovVoice';

export type CTAType = {
  name: string;
  description: string;
  id: string;
  expanded?: boolean;
};

export type PromptBuilderFormTypes = {
  id: string;
  name: string;
  body: string;
  ctaType: PromptCTATypes;
  ctaValue?: CTAType[];
  userList: OrgUser[];
  promptDateTime: string;
  categoryId: string;
  isGroovVoice: boolean;
  responseToOpenText?: string;
  promptTags: CommonSelectorOptionType[];
};

export type PromptBuilderFormProps = {
  title: string;
  initialValues: PromptBuilderFormTypes;
  editMode?: boolean;
};

export enum PromptCTATypes {
  custom = 'action_buttons',
  sixPtScale = '6pt_scale',
  openEndedText = 'open_text',
  customAndOpenEndedText = 'action_w_open_text',
}
