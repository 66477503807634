import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

export function AnalyticsInfo({
  label,
  value,
  subValue,
}: {
  label: string;
  value: string | number;
  subValue: string | number;
}) {
  const classes = useStyles();

  return (
    <Box className={classes.infoBox}>
      <Typography variant="subtitle1" color="textSecondary" className={classes.typeText}>
        {label}
      </Typography>
      <Typography className={classes.percentageBox}>
        {value}
        <span className={classes.percentageSpan}>{subValue}</span>
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  infoBox: {
    height: 100,
    boxShadow: theme.shadows[4],
    borderRadius: 6,
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'fit-content',
    minWidth: 100,
    maxWidth: 250,
    margin: theme.spacing(2),
  },
  percentageBox: {
    fontSize: theme.spacing(8),
    fontWeight: 'lighter',
    textAlign: 'center',
    lineHeight: '64px',
  },
  percentageSpan: {
    fontSize: theme.spacing(4),
    fontWeight: 200,
    marginLeft: theme.spacing(1),
  },
  typeText: {
    fontWeight: 'lighter',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: 'black',
  },
}));
