export const colors = {
  // Whites
  white: '#FFFFFF',
  whisperWhite: '#F8FCFF',
  whisperWhiteDark: '#f6f6f6',
  snow: '#F8FCFF',
  // Greys
  darkGrey: '#22262E',
  slateGrey: '#565E74',
  slateGrey2: '#787C81',
  slateGrey3: '#6A707F',
  gray1: '#A5ABB7',
  lightGrey: '#F2F5F9',
  mysticGrey: '#E6EBF3',
  // Blacks
  black: '#000000',
  // Reds
  lightRed: '#FDE5D8',
  redWarn: '#D73C3C',
  paleRed: '#ff9a88',
  // Greens
  green: '#40B766',
  positiveGreen: '#128743',
  darkestGreen: '#0D7442',
  lighterGreen: '#9EF3A8',
  lightestGreen: '#CDF9CE',
  // Blues
  blue: '#006AFF',
  darkBlue: '#0051DB',
  lightBlue: '#99D0FF',
  royalBlue: '#456DE6',
  // Oranges
  orange: '#ff554f',
};
