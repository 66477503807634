import { AxiosRequestConfig } from 'axios';
import { ApiRequest } from 'services/request';
import { dispatch, getStore } from 'store';
import { getGroovVoiceFilters, setCurrentSelectedPromptDetails } from './GroovVoice.slice';
import { PromptItemType } from './GroovVoice.d';
import moment from 'moment';

export const colorPalette = [
  '#006AFF',
  '#74E4C6',
  '#8C6B8C',
  '#7558FF',
  '#B55D35',
  '#D95A96',
  '#F0CEE2',
  '#0D4027',
  '#FBAA23',
  '#EE3130',
  '#6EBFE2',
];

const BASE_SERVICE_URL = process.env.REACT_APP_SERVICE_URL;

export const getRandomColor = (index?: number): string => {
  if (index?.toString()) {
    return colorPalette[index % colorPalette.length];
  }
  return colorPalette[Math.floor(Math.random() * colorPalette.length)];
};

export const getGroovVoicePrompts = async (
  startTime: string = getFirstDayOfMonth(),
  endTime: string = getCurrentDate()
) => {
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `conversation/partner/prompts/report?startTimeUtc=${startTime}T00:00:00&endTimeUtc=${endTime}T23:59:59`,
    method: 'GET',
  };

  const result = await ApiRequest(config);
  return result;
};
export const getFirstDayOfMonth = (): string => {
  return moment().startOf('month').format('YYYY-MM-DD');
};

export const getCurrentDate = (): string => {
  return moment().format('YYYY-MM-DD');
};

export const getAndSetCurrentSelectedPromptDetails = async (id: string) => {
  const { createdFrom, createdTo } = getGroovVoiceFilters(getStore().getState());
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `conversation/partner/prompts/report/${id}?startTimeUtc=${createdFrom}&endTimeUtc=${createdTo}`,
    method: 'GET',
  };

  const result = await ApiRequest(config);
  if (result.status === 200) {
    dispatch(setCurrentSelectedPromptDetails(result.data));
  }
};

export const getUniqueTags = (prompts: PromptItemType[]): string[] => {
  const allTags = prompts?.flatMap((prompt) => prompt.tags);
  return Array.from(new Set(allTags));
};

export const getPromptTags = (prompt: PromptItemType) => {
  const typeMap = [
    { id: 'action_buttons', label: 'Action Buttons' },
    { id: '6pt_scale', label: '6pt Scale' },
    { id: 'open_text', label: 'Open Text' },
    { id: 'action_w_open_text', label: 'Action Buttons with Open Text' },
  ];
  const typeTag = typeMap.filter((type) => type.id === prompt?.properties?.prompt_cta_type)?.[0];

  const otherTags = prompt?.tags.map((p) => ({ id: p, label: p })) ?? [];

  if (typeTag) {
    return [typeTag, ...otherTags];
  }
  return otherTags;
};
