import { AxiosError, AxiosRequestConfig } from 'axios';
import { BasicRequest } from 'services/apiRequest';
import { CustomerIntegration } from './types';

const BASE_URL = `${process.env.REACT_APP_SERVICE_URL}`;

export const addTenantId = async (
  orgId: string,
  tenantId: string,
  state: string,
  platform: string
) => {
  try {
    const authId = await getIntegrationAuthorization(orgId, platform);

    if (authId !== state) {
      throw new AxiosError('Installation not recognised', '500');
    }

    return await createCustomer(orgId, tenantId, authId, platform);
  } catch (error: any) {
    throw error;
  }
};

export const createCustomer = async (
  orgId: string,
  tenantId: string,
  authId: string,
  platform: string
): Promise<boolean> => {
  const config: AxiosRequestConfig = {
    url: 'workplace-integration/Customers',
    method: 'POST',
    data: {
      integrationId: tenantId,
      authorizationId: authId,
      orgId: orgId,
      platform: platform,
    },
    baseURL: BASE_URL,
  };

  const res = await BasicRequest(config);
  return res.status === 200;
};

export const getIntegrationAuthorization = async (
  orgId: string,
  platform: string
): Promise<string> => {
  const config: AxiosRequestConfig = {
    url: 'workplace-integration/integrationauthorizations',
    method: 'POST',
    data: {
      orgId: orgId,
      platform: platform,
    },
    baseURL: BASE_URL,
  };

  const res = await BasicRequest(config);
  return res.data.authorizationId;
};

export const getCustomerIntegrations = async (orgId: string): Promise<CustomerIntegration[]> => {
  const config: AxiosRequestConfig = {
    url: `workplace-integration/customers/${orgId}/integrations`,
    method: 'GET',
    baseURL: BASE_URL,
  };

  const res = await BasicRequest(config);
  return res.data.customerIntegrations;
};
