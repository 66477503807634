export enum RoutePath {
  ManageDomains = '/manage/domains',
  ContextualNudge = '/manage/nudge',
  AddContextualNudge = '/manage/nudge/create',
  EditContextualNudge = '/manage/nudge/edit',
  ManageUsers = '/manage/users',
  Registrations = '/registrations',
  Insights = '/insights',
  Invoices = '/invoices',
  HubBeta = '/hubBeta',
  Hub = '/hub',
  Help = '/help',
  Login = '/login',

  LoginCallback = '/auth-callback',
  LogoutCallback = '/logout-callback',

  ForgotPassword = '/forgotpassword',
  SetNewPassword = '/setnewpassword',
  SetNewPasswordResult = '/setnewpasswordresult',
  Root = '/',
  CheckEmail = '/checkemail',
  Settings = '/settings',
  GroovVoice = '/groov-voice',

  ManageRituals = '/manage/ritual',
  CreateCompany = '/ritual/create', // TODO url should be /manage/company/create
  EditCompany = '/ritual/edit/:id',
  CreateAdminAccount = '/create',
  Error = '/error',
  TeamsInstallRedirect = '/teamsredirect',
  TeamsInstall = '/teamsinstall',
  Integrations = '/integrations',
  GAInsights = '/ga-insights',
  GASettings = '/ga-settings',
  PromptBuilder = '/prompt-builder',
  PromptPreview = '/prompt-preview',
}

export enum RouteLabel {
  HubBeta = 'Hub',
  Insights = 'Insights',
  ManageUsers = 'Add/Remove',
  People = 'People',
  ManageDomains = 'Domains',
  ContextualNudge = 'Prompt Builder',
  Settings = 'Settings',
  GAInsights = 'GA Insights',
  GASettings = 'GA Settings',
  Help = 'Help',
  Ritual = 'Rituals',
  Integrations = 'Integrations',
  PromptBuilder = 'Prompt Builder',
  GroovVoice = 'Groov Voice',
}

export enum RouteId {
  HubBeta = 'hub',
  Insights = 'insights',
  ManageUsers = 'manage',
  People = 'people',
  ManageDomains = 'domains',
  ContextualNudge = 'nudge',
  Settings = 'settings',
  GAInsights = 'ga-insights',
  GASettings = 'ga-settings',
  Help = 'help',
  Ritual = 'ritual',
  Integrations = 'integrations',
  PromptBuilder = 'prompt-builder',
  GroovVoice = 'groovVoice',
}

export enum OrgOverviewRoute {
  highlights = 'highlights',
}

export enum RouteType {
  Public = 'Public',
  Private = 'Private',
}

export enum ManageRitualRoute {
  active_rituals = 'active_rituals',
  inactive_rituals = 'inactive_rituals',
}

export const IsAuthenticatedDefaultRoute = RoutePath.ManageDomains;

export interface Link {
  id: RouteId;
  name: RouteLabel;
  route: RoutePath;
  icon: React.ReactNode;
}

export type Links = {
  [key in RouteLabel]?: Link;
};
