import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Box, FormLabel, makeStyles, Typography } from '@material-ui/core';
import { useAppSelector } from 'store';
import { isEqual } from 'lodash';
import { setDetailsScreenFilters } from '../../GroovVoice.slice';
import { CommonSelectorOptionType } from '../../GroovVoice.d';
import { SearchAndSelect } from '../Common/SearchAndSelect';

const useStyles = makeStyles((theme) => ({
  container: {
    borderTop: '1px solid',
  },
  innerBox: {
    padding: theme.spacing(2),
  },
  sectionBox: {
    padding: theme.spacing(1),
  },
  label: {
    color: '#000',
  },
  dateField: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  selectField: {
    height: 40,
    marginTop: theme.spacing(1),
  },
  chip: {
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
}));

export function DetailsFilters() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { detailScreenFilters } = useAppSelector(
    (state) => ({
      detailScreenFilters: state.groovVoice.detailScreenFilters,
    }),
    isEqual
  );

  const handleSelectChange = useCallback(
    (name: string, value: CommonSelectorOptionType[]) => {
      dispatch(setDetailsScreenFilters({ [name]: value }));
    },
    [dispatch]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box className={classes.container}>
        <Box className={classes.innerBox} my={2}>
          <Typography variant="h3">Filters</Typography>

          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>Manager</FormLabel>
            <SearchAndSelect
              options={['Survey', 'Prompt'].map((o) => ({ id: o, label: o }))}
              value={detailScreenFilters.manager}
              handleOnChange={(_, value) => handleSelectChange('manager', value)}
              placeholder="Manager"
            />
          </Box>
          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>Office Location</FormLabel>
            <SearchAndSelect
              options={['Survey', 'Prompt'].map((o) => ({ id: o, label: o }))}
              value={detailScreenFilters.officeLocation}
              handleOnChange={(_, value) => handleSelectChange('officeLocation', value)}
              placeholder="Office Location"
            />
          </Box>
          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>Department</FormLabel>
            <SearchAndSelect
              options={['Survey', 'Prompt'].map((o) => ({ id: o, label: o }))}
              value={detailScreenFilters.department}
              handleOnChange={(_, value) => handleSelectChange('department', value)}
              placeholder="Department"
            />
          </Box>
          <Box className={classes.sectionBox} my={2}>
            <FormLabel className={classes.label}>Job Title</FormLabel>
            <SearchAndSelect
              options={['Survey', 'Prompt'].map((o) => ({ id: o, label: o }))}
              value={detailScreenFilters.jobTitle}
              handleOnChange={(_, value) => handleSelectChange('jobTitle', value)}
              placeholder="Job Title"
            />
          </Box>
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
}
