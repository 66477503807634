import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';
import { PromptTags } from '../Common/PromptTags';
import history from 'utils/history';
import { format } from 'date-fns';
import Markdown from 'markdown-to-jsx';
import { AnalyticsInfo } from '../Common/AnalyticsInfo';
import { PromptItemType } from '../../GroovVoice.d';
import { useAppSelector } from 'store';
import { getPromptTags } from 'pages/GroovVoice/GroovVoice.utils';

export function PromptInformation({ prompt }: { prompt: PromptItemType }) {
  const classes = useStyles();
  const promptDetails = useAppSelector((state) => state.groovVoice.currentSelectedPromptDetails);

  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const promptBodyRef = useRef<HTMLDivElement>(null);

  const responseRate = (prompt?.responseRate ?? 0) * 100;

  const favorable =
    promptDetails?.responses.filter((p) => p.text === 'Strongly agree' || p.text === 'Agree')
      ?.length ?? 0;

  const unfavorable =
    promptDetails?.responses.filter((p) => p.text === 'Strongly disagree' || p.text === 'Disagree')
      ?.length ?? 0;

  const mean = useMemo(() => {
    const stronglyAgreeCount =
      promptDetails?.count.filter((c) => c.text === 'Strongly agree')?.length ?? 0;
    const agreeCount = promptDetails?.count.filter((c) => c.text === 'Agree')?.length ?? 0;
    const stronglyDisagreeCount =
      promptDetails?.count.filter((c) => c.text === 'Strongly disagree')?.length ?? 0;
    const disagreeCount = promptDetails?.count.filter((c) => c.text === 'Disagree')?.length ?? 0;
    const neitherAgreeOrDisagreeCount =
      promptDetails?.count.filter((c) => c.text === 'Neither agree nor disagree')?.length ?? 0;

    const total =
      stronglyAgreeCount * 5 +
      agreeCount * 4 +
      neitherAgreeOrDisagreeCount * 3 +
      disagreeCount * 2 +
      stronglyDisagreeCount;

    const totalValidResponse =
      stronglyAgreeCount +
      agreeCount +
      neitherAgreeOrDisagreeCount +
      disagreeCount +
      stronglyDisagreeCount;

    return (total / totalValidResponse).toFixed(1);
  }, [promptDetails?.count]);

  const formattedRate = Number.isInteger(responseRate)
    ? responseRate.toString()
    : responseRate.toFixed(1);

  useEffect(() => {
    const checkOverflow = () => {
      const element = promptBodyRef.current;
      if (element) {
        setIsOverflowing(element.scrollHeight > element.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Box>
      <Typography className={classes.promptBreadcrumbs}>
        <Button variant="text" size="small" onClick={handleGoBack}>
          <Typography className={classes.promptBreadcrumbsLink}>Prompts</Typography>
        </Button>
        / {prompt?.promptName}
      </Typography>
      <Box mt={8}>
        <PromptTags tags={getPromptTags(prompt)} />
        <Typography variant="h2" className={classes.spacing}>
          {prompt?.promptName}
        </Typography>
        <Typography className={classes.promptId}>{prompt?.promptId}</Typography>
        {prompt?.lastSentUtc && (
          <Typography variant="body2" className={classes.promptDateTime}>
            <span className={classes.boldText}>Sent</span>
            {format(new Date(prompt?.lastSentUtc), 'yyyy-MM-dd hh:mm a')}
          </Typography>
        )}
        <Typography className={classes.boldLabel}>Prompt</Typography>
        <div
          ref={promptBodyRef}
          className={`${classes.promptBody} ${expanded ? classes.expanded : ''}`}>
          <Typography>
            <Markdown>{prompt?.promptText ?? ''}</Markdown>
          </Typography>
        </div>
        {isOverflowing && (
          <Button onClick={handleExpandClick} className={classes.expandButton}>
            {expanded ? 'See less' : 'See more'}
          </Button>
        )}
      </Box>
      <Box mt={4} className={classes.analyticsInfoContainer}>
        <AnalyticsInfo label="Rate" value={formattedRate} subValue="%" />
        <AnalyticsInfo
          label="Responses"
          value={prompt?.totalResponses ?? 0}
          subValue={`/${prompt?.totalSent}`}
        />
        {prompt?.properties?.prompt_cta_type === '6pt_scale' && (
          <>
            <AnalyticsInfo label="Mean" value={mean} subValue="" />
            <AnalyticsInfo
              label="Favorable"
              value={parseFloat(((favorable / prompt.totalSent) * 100).toPrecision(4))}
              subValue="%"
            />
            <AnalyticsInfo
              label="Unfavorable"
              value={parseFloat(((unfavorable / prompt.totalSent) * 100).toPrecision(4))}
              subValue="%"
            />
          </>
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  promptBreadcrumbs: {
    textTransform: 'none',
    color: '#333',
  },
  promptBreadcrumbsLink: {
    textTransform: 'none',
    color: theme.palette.primary.main,
  },
  promptId: {
    marginBottom: 16,
  },
  promptDateTime: {
    fontSize: 16,
    marginBottom: 16,
    fontWeight: 400,
    color: 'black',
  },
  boldText: {
    fontWeight: 'bolder',
    paddingRight: 16,
  },
  boldLabel: {
    fontSize: 16,
    marginBottom: 16,
    color: 'black',
    fontWeight: 'bolder',
  },
  promptBody: {
    minHeight: '50px',
    maxHeight: '50px',
    overflowY: 'hidden',
    position: 'relative',
    color: 'black',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '32px',
      background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))',
    },
  },
  expanded: {
    maxHeight: 'none',
    overflowY: 'visible',
    '&::after': {
      content: 'none',
    },
  },
  expandButton: {
    display: 'block',
    marginTop: theme.spacing(2),
    textTransform: 'none',
    color: 'black',
    textAlign: 'left',
    padding: 0,
  },
  analyticsInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  spacing: {
    marginTop: 12,
  },
}));
