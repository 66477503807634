import React from 'react';
import { Chip, Box, makeStyles } from '@material-ui/core';
import { PromptTag } from '../../GroovVoice.d';

interface PromptTagsProps {
  tags: PromptTag[];
  selectedTags?: string[];
  onClick?: (id: string) => void;
}

export function PromptTags({ tags, selectedTags, onClick }: PromptTagsProps) {
  const classes = useStyles();

  return (
    <Box display="flex" flexWrap="wrap" flexDirection="row" mt={2} gridRowGap={12}>
      {tags.map((tag) => (
        <Chip
          key={tag.id}
          label={tag.label}
          clickable
          color={selectedTags?.includes(tag.id) ? 'primary' : 'default'}
          onClick={() => onClick?.(tag.id)}
          className={classes.chip}
        />
      ))}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  chip: {
    borderRadius: 4,
    marginRight: theme.spacing(2),
  },
}));
