export const confirmAlertPrompts = {
  title: `Confirm`,
  description: `Are you sure you want to sign out?`,
  confirmButtonText: `Yes`,
  cancelButtonText: `No`,
};

export const toasterPrompts = {
  titles: {
    success: `Yay, all done`,
    error: `Uh oh, something went wrong`,
  },
  messages: {
    domain: {
      addSuccess: `Domain added successfully`,
      addError: `Sorry, there was a problem with your request`,
      removeSuccess: `Domain removed successfully`,
      removeError: `Sorry, there was a problem with your request`,
    },
    user: {
      addSuccess: `Individual added successfully`,
      addError: `Sorry, there was a problem with your request`,
      removeSuccess: `Individual removed successfully`,
      removeError: `Sorry, there was a problem with your request`,
      removeOneselfError: `Sorry, this account can't be removed`,
    },
    users: {
      addSuccess: `Individuals added successfully`,
      addError: `All individuals were previously added`,
      removeSuccess: `Individuals removed successfully`,
      removeError: `Sorry, there was a problem with your request`,
    },
    token: {
      expireError: `Your session has timed out due to inactivity. Please sign in again.`,
    },
    login: {
      invalidCredentials: `Invalid account credentials.`,
    },
  },
  hub: {
    surveyDataNotAvailable: 'Survey data is not available!',
    appAdoptionDataNotAvailable: 'App adoption rate data not available!',
  },
};

export const instructions = {
  addOrRemovePage: {
    addIndividual: `To add an individual, simply add their email address to the field below, or upload a CSV file to add several people / staff members at once.`,
    removeIndividual: `To remove an individual simply add their email address to the field below or upload a CSV file to remove several people / team members at once.`,
  },
  manageDomainsPage: {
    manageDomains: `Looking for a simple way to set up your account? You can allow staff to access Groov using your
    organisation’s domain name. This will allow anyone who has a valid email address from your domain to
    register and associate their account with your workplace.`,
  },
  helpPage: {
    needAssistance: `Here you will find some helpful resources. If you have any questions, or you’re having trouble getting set up, please don’t hesitate to contact us at support@groovnow.com.`,
  },
  manageRitualPage: {
    manageRituals: `A simple way to bake wellbeing into your workplace is to create rituals.
        The idea is to link a wellbeing action, like group deep breathing, to something in your work day (a trigger), such as a regular meeting.`,
  },
};

export const ManageUserTilePrompts = {
  addTile: {
    title: 'Add individual',
    placeholder: 'Add email address',
    buttonText: 'Add',
    linkText: 'Bulk add CSV',
  },
  removeTile: {
    title: 'Remove individual',
    placeholder: 'Remove email address',
    buttonText: 'Remove',
    linkText: 'Bulk remove CSV',
  },
  title: 'Add/Remove',
};

export const HeaderPrompts = {
  signOutButtonText: 'Sign out',
};

export const OrgOverviewPrompts = {
  navHeaders: {
    highlights: 'Highlights & Recommendations',
  },
  title: 'Insights',
};

export const HighlightPrompts = {
  highlightsNotFound: 'There are no highlights to show at the moment, please check back later.',
};

export const HubBetaPrompts = {
  manageSurveysButtonText: 'Manage surveys',
  pdfModalTitle: "We're still building..",
  errorTextCards:
    'Failed to get API survey data. Check that you have the REACT_APP_HUB_URL env variable set',
  filterTrendingLabel: 'Trending',
  filterModulesLabel: 'Module',
  filterRitualTeamsLabel: 'Ritual Teams',
  filterSurveyLabel: 'Selected survey',
  dateFormat: 'dd/MM/yy',
  selectDateFormat: 'd MMMM yyyy',
  filterDateFormat: 'dd MMM yyyy',
  recommendationDateFormat: 'MMM yyyy',
  timeFormat: 'hh:mm aa',
  trendingFilterLabels: {
    none: 'None',
    prev1: 'Previous 1 month',
    prev2: 'Previous 2 months',
    prev3: 'Previous 3 months',
    prev6: 'Previous 6 months',
    prev12: 'Previous 12 months',
    all: 'All time',
  },
};

export const HubCardPrompts = {
  number_of_response: 'Number of respondents',
  workplace_wellbeing: 'Workplace mental wellbeing score',
  individual_wellbeing: 'Individuals mental wellbeing score',
  wellbeing_activities: 'Wellbeing activities',
  app_adoption: 'App',
  modules: 'Modules',
  rituals: 'Rituals',
  workplace_cares_my_wellbeing: 'My workplace cares about my wellbeing',
  noDataMessage: 'Create and send out surveys to start collecting data',
  noDataTitle: 'Data not available',
  noDataText: 'Data not available',
  noDataTillDateText: 'New survey results will be available in {num} days',
  noDataUntilTodayText: 'New survey results will be available later today',
  noDataWithoutDateText: 'New survey results will be available soon',
  lessThanOneText: '< 1',
};

export const HubSurveyPrompts = {
  title: {
    selectSurvey: 'Select your survey',
    addRecipients: 'Add recipients',
    reviewAndSend: 'Review and send',
    surveySent: 'Survey sent!',
    manageSurveys: 'Manage surveys',
    addMoreRecipients: 'Add more recipients',
    selectDate: 'Select date & time',
    pleaseConfirm: 'Please confirm',
    noPastTime: 'Past time not allowed',
  },
  message: {
    addRecipients:
      'To add recipients to the survey please upload a CSV file of the recipients’ email addresses in the required format.',
    addMoreRecipients: `To add more recipients to the {surveyName} survey please upload a CSV file of the recipients’ email addresses in the required format.`,
    surveySent: 'That’s you all done. Check back in 1 week for your results.',
    reviewAndSend: `You are about to send this survey to {recipients} {recipientLabel}.\nYour survey will be sent on {selectedDate} at {selectedTime}, and will close 1 week later on {calculatedDate}\nThis action cannot be undone.`,
    manageSurvey: `You have no {surveyType} surveys.\nClick the “New Survey” button to create one now!`,
    manageReviewAndSend: `You are about to add {recipients} {recipientLabel} to this survey.\nYour survey will be sent today. Please note that the closing date for the survey will not change.`,
    manageSurveySent: 'Your survey has been sent to the additional recipients.',
    selectDate:
      'Choose the date that you would like to send this survey on. Surveys automatically close 1 week from the day they are sent.',
    noPastTime: 'Auto selected the current time',
  },
  buttonText: {
    preview: 'Preview',
    next: 'Next',
    uploadCSV: 'Upload CSV',
    sendNow: 'Send now',
    backToDashboard: 'Back to Dashboard',
    downloadSampleCSV: 'Download sample CSV file',
    newSurvey: 'New survey',
    addMoreRecipients: 'Add more recipients',
    changeDate: 'Change date',
    confirmSend: 'Confirm send',
    datePickerDone: 'Done',
    datePickerCancel: 'Cancel',
    recommendationShowMore1: '{count} more commentary available',
    recommendationShowMore: '{count} more commentaries available',
    recommendationShowLess: 'Show Less',
  },
  label: {
    available: 'Available',
    active: 'Currently active',
    lastSentDate: 'Last sent on {date}',
    closeOnDate: 'Closes on {date}',
    closedOnDate: 'Closed on {date}',
    loadingCSV: 'Loading CSV',
    sending: 'Sending',
    activeSurveys: 'Active surveys',
    completeSurveys: 'Complete surveys',
    selectDate: 'Send my survey on {date} at {time}',
    selectDateReminderTitle: 'Send reminder emails for this survey?',
    selectDateReminderMessage: 'Reminders are sent {day} days after the initial survey is sent.',
    dataNotAvailable: 'Data not available',
    recommendationTimePeriod: 'Commentary for {date}',
  },
};

export const HubRitualPrompts = {
  title: {
    overview: 'Overview',
    noDataOverview: 'No rituals',
    noDataCheckin: `No team rituals with at least 60% check-in response rate`,
    glanceSection: 'At a glance...',
    glanceBody: 'Check-in results',
    glanceSectionOne: 'How valuable have teams found their rituals?',
    glanceSectionTwo: 'How often have teams been sticking to their rituals?',
    glanceSectionThree: 'Check-in response rate',
  },
  label: {
    companyRituals: 'Company rituals',
    teamRituals: 'Team rituals',
    recentRituals: 'New/updated rituals this month',
    glanceDisclaimer:
      '**Results only include instances where at least 60% of the team completed a ritual check-in request.',
    glanceViewMore: 'View more details',
    glanceSectionOneMainFigurePrimary: '% responded',
    glanceSectionOneMainFigureSecondary: 'Valuable',
    glanceSectionTwoMainFigurePrimary: '% responded',
    glanceSectionTwoMainFigureSecondary: 'Always OR Sometimes',
    glanceSectionThreeMainFigurePrimary: '% average response rate',
    glanceSectionValuableDeltaText: '% comparing to previous 12 months',
    glaceSectionOftenDeltaText: '% comparing to previous 12 months',
    glanceSectionResponseRateDeltaText: '% comparing to previous',
    valuableOftenNoValueText: '0',
    valuableOftenLessThanOneText: '< 1',
    ritualTeamsOveriew: 'Overview',
  },
  buttonText: {
    noDataLearnMore: 'Learn more',
    back: 'Back',
  },
};
export const manageRitualPrompts = {
  navHeaders: {
    active_rituals: 'Active rituals',
    inactive_rituals: 'Inactive rituals',
  },
  createRitual: 'Create new Ritual',
  manageCompany: 'Manage company rituals',
  errorMessage: 'Something went wrong please try again',
  noDataFound: 'No data found',
  executiveSponsor: 'Executive sponsor',
  triggers: 'Triggers',
  actions: 'Actions',
  create: {
    editRitual: 'Edit Ritual',
    createRitual: 'Create Ritual',
    trigger: 'Trigger',
    action: 'Action',
    planning: 'Planning',
    planningText: `This is Your space to plan for this Ritual. It's internally facing and won't be
                shared with the team.`,
    executiveSponser: 'Executive sponsor',
    keyMessage: 'Key message',
    plannedDate: 'Planned date',
    plannedDatePlaceholder: 'dd/mm/yyyy',
    plannedDateFormat: 'DD/MM/YYYY',
    comms: 'Comms',
    actionPlan: 'Action plan',
    ritualStatus: 'Ritual status',
    updateRitual: 'Update Ritual',
    deleteRitual: 'Delete Ritual',
    cancel: 'Cancel',
    editSuccessTitle: `Ritual updated successfully!`,
    createSuccessTitle: `Great! You've created new rituals`,
    description: 'Your changes have been saved',
    confirmButtonText: 'Ok',
  },
  placeholder: {
    trigger: 'Example: At the begining of every meeting',
    activeStatus: 'Ritual is currently active and visible to team',
    inActiveStatus: 'Ritual is currently inactive and not visible to team',
    action:
      'Example: Share one thing you did well, one thing you learned, and one thing you want to improve',
    sponsorName: 'Example: Jane Smith',
    sponsorRole: 'Example: CEO',
    ketMessage: 'Example:',
  },
};

export const contextualNudgePrompts = {
  manageNudge: 'Manage Contextual Nudge Prompt',
  createNudge: 'Create Contextual Nudge Prompt',
  noDataFound: 'No data found',
  createPrompt: 'Prompt Builder: Create Prompt',
  updatePrompt: 'Prompt Builder: Update Prompt',
  PromptName: 'Name',
  PromptDetails: 'Details',
  PromptOptions: 'Options',
  PromptDescription: 'Description',
  PromptTrigger: 'Trigger',
  PromptSchedule: 'Rules',
  PromptGroup: 'Group',
  PromptNameDesc: 'Name',
  PromptDetailsDesc: 'Details',
  PromptOptionsDesc: 'Options',
  PromptDescriptionDesc: 'Description',
  PromptTriggerDesc: 'Trigger',
  PromptScheduleDesc: 'Rules',
  PromptGroupDesc: 'Group',
  PromptActions: 'Actions',
  promptCreate: 'Create Prompt',
  promptUpdate: 'Update Prompt',
  promptTriggerRelation: 'Trigger Relationship',
  promptRuleRelation: 'Rule Relationship',
  promptTriggerRelationDesc: 'Trigger Relationship',
  promptRuleRelationDesc: 'Rule Relationship',
};

export const promptBuilder = {
  previewPrompt: 'Preview Prompt',
  createPrompt: 'Create Prompt',

  promptName: 'Name',
  promptNameDescription: 'Enter a name to identify this prompt.',

  promptDateAndTime: 'Schedule Delivery',
  promptDateAndTimeDescription: 'Select the date and time for this message to be sent to users.',

  promptBody: 'Message',
  promptBodyDescription: 'Compose the message that will be displayed to users.',

  promptCTA: 'Response Type',
  promptCTADescription: 'Choose how you want users to respond to this message.',

  promptCTACustom: 'Action Buttons',
  promptCTACustomDescription:
    'Create buttons for users to click (one button is expected to be clicked).',
  promptCTASixPointScale: '6pt Scale',
  promptCTASixPointScaleDescription: 'Create 6 point scale button options.',
  promptCTACustomAndOpenEndedText: 'Custom + Open Ended Text Input',
  promptCTACustomAndOpenEndedTextDescription:
    'Create button options for users to select and allow them to add a custom text response for more detailed feedback.',
  promptCTAOpenEndedText: 'Text Response',
  promptCTAOpenEndedTextDescription: 'Allow users to submit a custom text response.',
  promptCTANone: 'No Response',
  promptCTANoneDescription: 'Define a prompt without user response.',

  promptAddCTA: 'Add Action Button',
  promptAddCTADescription: 'Create Response options for your message',

  promptAddNewCTA: 'Add Another Action',

  promptCTAButtonTextLabel: 'Button Text',
  promptCTAButtonTextLabelDescription: 'Specify the text for the button.',
  promptCTAResponseTextLabel: 'Response Text',
  promptCTAResponseTextLabelDescription:
    'Describe the response that will be displayed when this button is clicked.',

  promptUserList: 'Upload Recipients',
  promptUserListDescription:
    'Upload a CSV file with the list of users who will receive this message.',
  promptUserListNotValid:
    'Uploaded CSV file is not in the correct format. The required format is a header row with: Email,OrgId',

  promptCreate: 'Preview',
  promptCancel: 'Cancel',

  promptSchedule: 'Schedule Prompt',
  promptUpdate: 'Update Prompt',
  promptBack: 'Back',
  promptCTATitle: 'CTA and Responses',

  promptSuccessMessage: 'Prompt Builder',
  promptSuccessDescription: 'Prompt created successfully.',

  confirmButtonText: 'Ok',

  promptCategory: 'Category',
  promptCategoryDescription: 'Select category for prompt.',

  promptGroovVoiceSwitch: 'Groov Voice Reporting',
  promptGroovVoiceSwitchDescription: '  ',

  promptResponseToOpenTextAndAction: 'Response Text to Open Ended Text Input',
  promptResponseToOpenTextAndActionDescription:
    'Describe the response that will be displayed when the user submits their answer.',
};
